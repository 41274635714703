import React, { useState } from "react";
import Gallery from "./Gallery";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Logo from "./components/Logo";
import Footer from "./components/Footer";
import Login from "./components/Login";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./utils/firebase";
import { signOut } from "firebase/auth";
import styled from "styled-components";
import MaterialButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";
import "./App.css";

const Header = () => {
  const [user] = useAuthState(auth);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState();

  const handleAccountClick = (event) => {
    setPopoverAnchorElement(event.currentTarget);
  };

  const popoverOpen = Boolean(popoverAnchorElement);

  return (
    <div>
      <center>
        <a href="/">
          <Logo src={require("./assets/logo.png")} />
        </a>
      </center>
      {user && (
        <div style={{ textAlign: "right", marginRight: 20 }}>
          <MaterialButton id="account-button" onClick={handleAccountClick}>
            {user.email}
          </MaterialButton>
          <Popover
            open={popoverOpen}
            anchorEl={popoverAnchorElement}
            onClose={() => setPopoverAnchorElement(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MaterialButton onClick={() => signOut(auth)}>
              Logg ut
            </MaterialButton>
          </Popover>
        </div>
      )}
    </div>
  );
};

const StyledLoading = styled(CircularProgress)`
  padding: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Loading = () => (
  <LoadingContainer>
    <StyledLoading />
  </LoadingContainer>
);

function App() {
  const [user, loading] = useAuthState(auth);
  return (
    <Router className="App">
      <Switch>
        <Route path="/collections/:shareId">
          <Header />
          <Gallery />
        </Route>
        <Route path="/:path+">
          <Header />
          {user && <Gallery />}
          {!user && !loading && <Login />}
          {loading && <Loading />}
        </Route>
        <Route path="/">
          <Header />
          {user && <Gallery />}
          {!user && !loading && <Login />}
          {loading && <Loading />}
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
